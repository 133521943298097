.add_div{
  width: 100%;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title{
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .btnCancel{
    margin-right: 10px;
  }
}
.suppTextUser{
  font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
text-align: left;
width: 220px;
}
.FilterUser{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin-top: 1%;
  @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      margin: 0.5rem;
      justify-content: center;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
    @media screen and (max-width: 1420px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
 
    @media screen and (max-width: 767px) {
     display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    gap: 0.5rem;

    }
    .divGlobalTable > #fixtheScroll{
      height: 87vh !important
    }
    .fixation{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 0.5rem;
    }
  .rect_colonne{
    box-shadow: 0 4px 10px 2px rgba(0,0,0,.12);
    flex-direction: row !important;
  }
  //   width: 17%;
  //   height: 40px !important;
  //   margin-right: 10%;
  //   .selectFilter{
  //     width: 100%;
  //     height: 40px !important;
  //     font-size: 16px;
  //     font-weight: 400;
  //     color: RGBA(0, 0, 0, 0.65);
  //   }
  //   .ant-select-selector{
  //     height: 40px !important;
  //     display: flex !important;
  //     align-items: center !important;
  //   }
  //   @media screen and (max-width: 1420px) {
  //     width: 20%;
  //   }
  //   @media screen and (max-width: 768px) {
  //     width: 30%;
  //   }
  //   @media screen and (max-width: 767px) {
  //     width: 100%;
  //     margin-bottom: 5px;
  //   }
  // }
  
  @media screen and (max-width: 768px) {
    .fix-the-corbeiller{
      display: flex;
      flex-direction: row !important;
      gap: 1rem;
      align-items: center !important;
    }
  }
       
  //
  .rect_filter{
    width: 100%;
    height: 40px;
    box-shadow: 0 4px 10px 2px rgba(0,0,0,.12);
flex-direction: row !important;
    .inputFilter{
      height: 100%;
    }
    .Filter .delete_number{
      width: 40px !important;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-inline: 1rem;
    }
    .filter_icon{
      width: 40px !important;
      height: 40px !important;
      padding: 5px; margin-right: -11px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.25);
      border-left: 1px solid #d9d9d9 !important;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    .class_icon_filter{
      font-size: 16px;
      transition: all 0.3s ease-in-out;
    }
    .filter_icon:hover .class_icon_filter{
      color: #40a9ff;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  }


}
.divGlobalTable{
   //.ant-card-bordered{
   //  height: 90vh;
   //  @media screen and (max-width: 768px){
   //    height: 90vh;
   //    overflow: hidden;
   //    overflow-y: scroll;
   //  }
   //  @media screen and (max-width: 767px){
   //    height: 85vh;
   //    overflow: hidden;
   //    overflow-y: scroll;
   //  }
   //}
  .dataTable{
    margin-top: 15px;
    overflow-y: scroll;
    height: auto;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
.inputmodal{
  width: 96%;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  margin-right: 4px;

}
.title{
  font-weight: bold !important;
  padding-top: 10px;
}
.actionUser{
  align-items: center;
display: flex;
flex-direction: row;
// justify-content: space-evenly;
justify-content: space-evenly;
width: 150px;
  div{
    font-size: 15px;
    .icon_action{
      cursor: pointer;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  @media screen and (max-width: 768px) {
    //width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 30px;
    .detailsUserDiv_spn1{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.45);
    }
    .detailsUserDiv_spn2{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}

.div_btn_add_cancel{
    display: flex;
    flex-direction: row !important;
}



.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



//********* responsive table *********//

.DataTable{
  @media screen and (max-width: 768px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      width: 100%;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border: 1px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      text-align: left;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
     td:last-child {
      border-bottom: 0;
       display: block;
       background-color: #ddd;
    }

  }
}
.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
.btnCancel{
  background-color: white ;
   border : solid 2px #1976D2 ;
   border-radius : 5px !important;
   padding: 2px 7px 2px 7px !important;
} 
.btnSubmit {
  background-color: #1976D2 ;
  border-radius : 5px !important;
  padding: 2px 7px 2px 7px !important;
}
.divGlobal{
  display: flex;
  flex-direction: column;
  background-color: white;
  // padding: 2%;
  .Breadcrumb{
    padding: 0.3%;
  }
}
.Profile{
  display: flex;
  flex-direction: row !important;
  height: auto;
  .ProfileData{
    width: 50%;

  }
  .ProfileSecurity{
    width: 50%;
    padding: 0 1.5rem;
  }
@media (max-width: 768px) {
  .ProfileSecurity {
    padding: 0;
  }
}

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column !important;
    .ProfileData{
      width: 100%;
      margin-right: 3%;
      margin-bottom: 3%;
    }
    .ProfileSecurity{
      width: 100%;
    }
  }
}
.Profile::-webkit-scrollbar{
  width: 4px !important;
  height: 4px;
}
.Profile::-webkit-scrollbar-track{
  background-color: var(--card_color) !important;
  z-index: 999;
  border: none !important;
  box-shadow: none !important;
}
.Profile::-webkit-scrollbar-thumb{
  background-color: var(--icon-color);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}
.btnClose{
border : 1px solid #1976D2 !important;
color: #1976D2 !important;
margin-left : 1% !important;
float : right !important;
margin-right : 32px !important; 
margin-top : 50px !important;
padding : 5px !important;
}
.folderIcon{
  margin-right: 10px !important;
}

.Search{
  padding: 2px 4px !important;
  display: flex !important;
   align-items: center !important;
    width: 100% !important;
      box-shadow: none !important;
      margin-left: 2% !important;
      width: 95% !important;
      margin-right: 5% !important;
        background-color:#F0F0F0 !important;
        opacity: 30% !important;
 }
 .searchTextField{
  width: 100% !important;
  border: none !important;
 }
 .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
  display:flex !important
 }
 .selectFile{
  margin-left: 20px !important;
 }
 .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  margin-top: 20px !important;
 }
 .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
  margin-top: 20px !important;

 }
 .upload-img{
width: 100% !important;
margin-left: 0em !important;
flex-direction: column !important;
 }
 .css-s18byi{
  box-shadow: none !important;
 }
.modal-confirm-title {
 width: auto !important; 
  @media screen and (max-width: 768px) {
 font-size: 16px !important;
 padding-left: 9px !important;
    }

} 

.select-group {
  min-width: 300px !important;
  padding: 0px 0px !important;

}
.css-cc8tf1{
  font-weight: bold !important;
}
.css-uhb5lp{
  max-width: calc(100% - 64px) !important;
}

.divGlobalTable .FilterUser .div_rect .MuiSelect-select{
  padding: 10px 130px 10px 14px;
}

.fix-the-corbeiller .css-igs3ac{
  border: medium none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px;
}

.header_title {
  color: rgba(0,0,0,.85);
  display: flex !important;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 18px;
  font-weight: 500;
  gap: .5em;
  white-space: nowrap;
}

.colomunHeaderUnique{
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: bolder !important;
  letter-spacing: 0.01071em !important;
  font-size: 14px !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.toghetherAllArrows{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // gap: 0.4rem;
}

.group-details-modal-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 1%;
  .group-details-modal-title{
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
  }
}

.changePassGap{
  gap: 15px;
display: flex;
flex-direction: column;
}